import { memo, useMemo } from 'react';
import styled from 'styled-components';

import useAlarmsContext from '~/context/useAlarmsContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import type { Agent } from '~/types/agent';
import { ALARM_TYPE } from '~/types/alarm';
import getEquipmentStatusLabel from '~/utils/equipment/getEquipmentStatusLabel';

import ContentGasSensors from './components/ContentGasSensors';
import ContentHeartRate from './components/ContentHeartRate';
import ContentImpactDetection from './components/ContentImpactDetection';
import ContentPhysiologicalTemperature from './components/ContentPhysiologicalTemperature';
import EquipmentItemCard, { type EquipmentItem } from './components/EquipmentItemCard';

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 12px;
`;

interface Props {
  agent: Agent | undefined;
}

const SectionEquipment = memo(({ agent }: Props) => {
  const { companyFeatures } = useCompanyFeatures();
  const { ongoingAlarms } = useAlarmsContext();

  const agentOngoingAlarms = useMemo(
    () => ongoingAlarms.filter((alarm) => alarm.carrier.id === agent?.id),
    [agent, ongoingAlarms],
  );

  const hasImpactDetectionAlarm = useMemo(
    () =>
      (agentOngoingAlarms || []).some((alarm) =>
        [ALARM_TYPE.traak_front, ALARM_TYPE.traak_back].includes(alarm.type),
      ),
    [agentOngoingAlarms],
  );

  const hasGasAlarm = useMemo(
    () =>
      (agentOngoingAlarms || []).some((alarm) =>
        [
          ALARM_TYPE.gasCH4HC,
          ALARM_TYPE.gasCO,
          ALARM_TYPE.gasCO2,
          ALARM_TYPE.gasH2S,
          ALARM_TYPE.gasO2,
        ].includes(alarm.type),
      ),
    [agentOngoingAlarms],
  );

  if (!agent) {
    return null;
  }

  const {
    connectionLost,
    emergencyButton,
    heartRate,
    physiologicalTemperature,
    bodyTemperature, // deprecated
    gas,
    traakFront,
    traakBack,
  } = agent.equipmentStatus;

  const list: EquipmentItem[] = [
    {
      key: 'brain',
      label: i18n.t('carrierDetailsPopup.equipment.brain'),
      isHealthy: !connectionLost.healthy,
      errorTooltip: undefined,
      content: null,
      isOpen: false,
    },
    ...(companyFeatures.emergencyButton
      ? [
          {
            key: 'emergencyButton',
            label: i18n.t('carrierDetailsPopup.equipment.sosExternalButton'),
            isHealthy: emergencyButton.healthy,
            errorTooltip: getEquipmentStatusLabel(emergencyButton),
            content: null,
            isOpen: false,
          },
        ]
      : []),
    ...(companyFeatures.heartRateSensor
      ? [
          {
            key: 'heartRateSensor',
            label: i18n.t('carrierDetailsPopup.equipment.heartRate'),
            isHealthy: heartRate.healthy,
            errorTooltip: getEquipmentStatusLabel(heartRate),
            content: companyFeatures.biometricData ? <ContentHeartRate agent={agent} /> : null,
            isOpen: false,
          },
        ]
      : []),
    ...(companyFeatures.physiologicalTemperatureSensor || companyFeatures.bodyTemperatureSensor
      ? [
          {
            key: 'physiologicalTemperatureSensor',
            label: i18n.t('carrierDetailsPopup.equipment.physiologicalTemperature'),
            isHealthy:
              (companyFeatures.physiologicalTemperatureSensor
                ? physiologicalTemperature.healthy
                : true) && (companyFeatures.bodyTemperatureSensor ? bodyTemperature.healthy : true),
            errorTooltip:
              (companyFeatures.physiologicalTemperatureSensor
                ? getEquipmentStatusLabel(physiologicalTemperature)
                : undefined) ||
              (companyFeatures.bodyTemperatureSensor
                ? getEquipmentStatusLabel(bodyTemperature)
                : undefined),
            content: companyFeatures.biometricData ? (
              <ContentPhysiologicalTemperature agent={agent} />
            ) : null,
            isOpen: false,
          },
        ]
      : []),
    ...(companyFeatures.gasSensor
      ? [
          {
            key: 'gasSensors',
            label: i18n.t('carrierDetailsPopup.equipment.gasSensors'),
            isHealthy: gas.healthy,
            errorTooltip: getEquipmentStatusLabel(gas),
            content: <ContentGasSensors agent={agent} />,
            isOpen: hasGasAlarm,
          },
        ]
      : []),
    ...(companyFeatures.impactDetectionFront || companyFeatures.impactDetectionBack
      ? [
          {
            key: 'impactDetection',
            label: i18n.t('carrierDetailsPopup.equipment.impactDetection'),
            isHealthy:
              (companyFeatures.impactDetectionFront ? traakFront.healthy : true) &&
              (companyFeatures.impactDetectionBack ? traakBack.healthy : true),
            errorTooltip:
              (companyFeatures.impactDetectionFront
                ? getEquipmentStatusLabel(traakFront)
                : undefined) ||
              (companyFeatures.impactDetectionBack
                ? getEquipmentStatusLabel(traakBack)
                : undefined),
            content: <ContentImpactDetection agent={agent} />,
            isOpen: hasImpactDetectionAlarm,
          },
        ]
      : []),
  ];

  return (
    <GridDiv data-id="SectionEquipment" key={hasImpactDetectionAlarm.toString()}>
      {list.map((item) => (
        <EquipmentItemCard key={item.key} item={item} />
      ))}
    </GridDiv>
  );
});

SectionEquipment.displayName = 'SectionEquipment';

export default SectionEquipment;
