import { useQuery, type ApolloError } from '@apollo/client';
import merge from 'lodash/merge';
import orderBy from 'lodash/orderBy';
import { useCallback, useEffect, useRef, useMemo } from 'react';

import QUERY_SUBSIDIARY_CARRIER_LIST, {
  type SubsidiaryCarrierListQueryData,
  type SubsidiaryCarrierListQueryVariables,
  type CarrierItem,
} from '~/apollo/operations/queries/QuerySubsidiaryCarrierList';
import SUBSCRIBE_TO_CARRIER, {
  type CarrierSubscriptionData,
  type CarrierSubscriptionVariables,
} from '~/apollo/operations/subscriptions/SubscribeToCarrier';
import SUBSCRIBE_TO_SUBSIDIARY_MEASUREMENT, {
  type SubsidiaryMeasurementSubscriptionData,
  type SubsidiaryMeasurementSubscriptionVariables,
} from '~/apollo/operations/subscriptions/SubscribeToSubsidiaryMeasurement';
import useCompanyFeatures, { type CompanyFeatures } from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import {
  GAS_SENSOR_TYPE,
  GAS_SENSOR_ALARM_TYPE,
  SENSOR_NAME_QUERY,
  SENSOR_NAME_VARIABLE,
  SENSOR_STATUS_QUERY,
  SENSOR_STATUS_VARIABLE,
} from '~/types/sensor';
import { STREAM_STATE } from '~/types/videoStream';
import logger from '~/utils/logger';
import notification from '~/utils/notification';
import parseJSON from '~/utils/parse/parseJSON';

const DEFAULT_CARRIER_ITEM: CarrierItem = {
  __typename: 'Carrier_Cognito',
  id: 'DEFAULT_CARRIER_ID',
  name: 'DEFAULT_CARRIER_NAME',
  attributes: [],
  video_stream: null,
  requested_video_stream_status: null,
  device: {
    __typename: 'Device',
    name: 'DEFAUT_DEVICE_NAME',
    attributes: [],
    connected: { __typename: 'MeasurementConnection', items: [] },
    gps: { __typename: 'MeasurementConnection', items: [] },
    heartRate: { __typename: 'MeasurementConnection', items: [] },
    heartRateStatus: { __typename: 'MeasurementConnection', items: [] },
    bodyMultiSensorV1: { __typename: 'MeasurementConnection', items: [] },
    bodyMultiSensorV1Status: { __typename: 'MeasurementConnection', items: [] },
    bodyTemperature: { __typename: 'MeasurementConnection', items: [] },
    bodyTemperatureStatus: { __typename: 'MeasurementConnection', items: [] },
    gas: { __typename: 'MeasurementConnection', items: [] },
    gasStatus: { __typename: 'MeasurementConnection', items: [] },
    brainStop: { __typename: 'MeasurementConnection', items: [] },
    connected_history: { __typename: 'MeasurementConnection', items: [] },
    traakFrontStatus: { __typename: 'MeasurementConnection', items: [] },
    traakBackStatus: { __typename: 'MeasurementConnection', items: [] },
    emergencyStatus: { __typename: 'MeasurementConnection', items: [] },
    battery: { __typename: 'MeasurementConnection', items: [] },
    batteryStatus: { __typename: 'MeasurementConnection', items: [] },
    lteSignalStrength: { __typename: 'MeasurementConnection', items: [] },
    lteSignalStrengthStatus: { __typename: 'MeasurementConnection', items: [] },
  },
};

export default function useQueryWithSubscriptionSubsidiaryCarrierList({
  subsidiaryID,
  skip = false,
}: {
  subsidiaryID: string;
  skip: boolean;
}): {
  subsidiaryCarrierList: CarrierItem[];
  isLoading: boolean;
  error?: ApolloError;
} {
  const { companyFeatures } = useCompanyFeatures();
  const { subscribeToMore, fetchMore, client, loading, error, data } = useQuery<
    SubsidiaryCarrierListQueryData,
    SubsidiaryCarrierListQueryVariables
  >(QUERY_SUBSIDIARY_CARRIER_LIST, {
    variables: { subsidiaryID },
    fetchPolicy: 'network-only',
    skip,
  });
  const subscriptionsRef = useRef<(() => void)[]>([]);
  const subscribedFeaturesRef = useRef<Set<keyof CompanyFeatures>>(new Set());

  const subscribeToMoreForCarrier = useCallback(
    (subsidiaryId: string) =>
      subscribeToMore<CarrierSubscriptionData, CarrierSubscriptionVariables>({
        document: SUBSCRIBE_TO_CARRIER,
        variables: { subsidiaryID: subsidiaryId },
        updateQuery: (previousResult, { subscriptionData }) => {
          const updatedItem = subscriptionData?.data?.carrier?.carrier;
          if (!updatedItem) {
            return previousResult;
          }
          const { id, name, attributes, video_stream: videoStream } = updatedItem;
          let itemList: CarrierItem[] = previousResult?.subsidiary?.carriers?.items || [];
          const isExistingItem = itemList?.some((item) => item.id === id);
          logger.log('useQueryWithSubscriptionSubsidiaryCarrierList: carrier update', {
            id,
            subsidiaryId,
            videoStream,
            subscriptionData,
          });
          if (isExistingItem) {
            itemList = itemList.map((item) =>
              item.id === id
                ? {
                    ...item,
                    name,
                    attributes,
                    video_stream: {
                      ivs_stream_state:
                        videoStream?.ivs_stream_state ||
                        item.video_stream?.ivs_stream_state ||
                        STREAM_STATE.END,
                    },
                  }
                : item,
            );
          } else {
            itemList = [merge({}, DEFAULT_CARRIER_ITEM, updatedItem), ...itemList];
          }
          const result: SubsidiaryCarrierListQueryData = {
            ...previousResult,
            subsidiary: {
              ...previousResult.subsidiary,
              id: previousResult.subsidiary?.id || subsidiaryId,
              carriers: {
                ...previousResult.subsidiary?.carriers,
                nextToken: previousResult.subsidiary?.carriers?.nextToken || null,
                items: itemList,
              },
            },
          };
          return result;
        },
      }),
    [subscribeToMore],
  );

  const subscribeToMoreForSensorType = useCallback(
    (
      subsidiaryId: string,
      sensorQueryName: SENSOR_NAME_QUERY | SENSOR_STATUS_QUERY,
      sensorName: SENSOR_NAME_VARIABLE | SENSOR_STATUS_VARIABLE,
    ) =>
      subscribeToMore<
        SubsidiaryMeasurementSubscriptionData,
        SubsidiaryMeasurementSubscriptionVariables
      >({
        document: SUBSCRIBE_TO_SUBSIDIARY_MEASUREMENT,
        variables: { subsidiaryID: subsidiaryId, type: sensorQueryName },
        updateQuery: (previousResult, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return previousResult;
          }
          const carrierId = subscriptionData.data.measurements?.carrier_id;
          let measurements = orderBy(
            subscriptionData.data.measurements?.measurements || [],
            'timestamp',
            'desc',
          );
          logger.log('useQueryWithSubscriptionSubsidiaryCarrierList: sensor update', {
            sensorName,
            carrierId,
            subsidiaryId,
            measurements,
          });
          let gasItem: (typeof measurements)[number] | undefined;
          let gasAlarmItem: (typeof measurements)[number] | undefined;
          if (sensorName === SENSOR_NAME_VARIABLE.gas) {
            gasItem = measurements.find(
              (measurement) =>
                typeof parseJSON(measurement?.value)?.[GAS_SENSOR_TYPE.o2] === 'number',
            );
            gasAlarmItem = measurements.find(
              (measurement) =>
                typeof parseJSON(measurement?.value)?.[GAS_SENSOR_ALARM_TYPE.o2_alarm] === 'number',
            );
            const gasAlarmValue: Record<GAS_SENSOR_ALARM_TYPE, number> = parseJSON(
              gasAlarmItem?.value,
            );
            if (
              gasAlarmValue?.[GAS_SENSOR_ALARM_TYPE.ch4_hc_alarm] > 0 ||
              gasAlarmValue?.[GAS_SENSOR_ALARM_TYPE.co_alarm] > 0 ||
              gasAlarmValue?.[GAS_SENSOR_ALARM_TYPE.co2_alarm] > 0 ||
              gasAlarmValue?.[GAS_SENSOR_ALARM_TYPE.h2s_alarm] > 0 ||
              gasAlarmValue?.[GAS_SENSOR_ALARM_TYPE.o2_alarm] > 0
            ) {
              // TODO: We should delete this when gas alarms are implemented in the backend
              // newAlertVar({ carrierId });
            }
          }
          const newItemList = previousResult?.subsidiary?.carriers?.items?.map((item) => {
            if (item.id === carrierId) {
              if (sensorName === SENSOR_NAME_VARIABLE.gas) {
                const items = item.device?.[sensorName]?.items || [];
                const defaultGasItem = { __typename: 'Measurement', timestamp: '', value: '' };
                measurements = [
                  gasItem || items[0] || defaultGasItem,
                  gasAlarmItem || items[1] || defaultGasItem,
                ];
              }
              const updatedItem: CarrierItem = {
                ...item,
                device: {
                  ...item.device,
                  [sensorName]: {
                    ...item.device?.[sensorName],
                    items: measurements,
                  },
                },
              };
              if (sensorName === SENSOR_NAME_VARIABLE.connected) {
                // Sort by timestamp ascending where the first item is the oldest
                const sortedConnectedHistory = orderBy(
                  [
                    ...(item.device?.[SENSOR_NAME_VARIABLE.connected_history]?.items || []),
                    ...measurements,
                  ],
                  'timestamp',
                  'asc',
                );
                const latestConnected = sortedConnectedHistory.at(-1);
                updatedItem.device[SENSOR_NAME_VARIABLE.connected_history] = {
                  items: sortedConnectedHistory,
                };
                updatedItem.device[SENSOR_NAME_VARIABLE.connected] = {
                  items: latestConnected ? [latestConnected] : measurements,
                };
              }
              return updatedItem;
            }
            return merge({}, DEFAULT_CARRIER_ITEM, item);
          });
          const result: SubsidiaryCarrierListQueryData = {
            ...previousResult,
            subsidiary: {
              ...previousResult.subsidiary,
              id: previousResult.subsidiary?.id || subsidiaryId,
              carriers: {
                ...previousResult.subsidiary?.carriers,
                nextToken: previousResult.subsidiary?.carriers?.nextToken || null,
                items: newItemList || [],
              },
            },
          };
          return result;
        },
      }),
    [subscribeToMore],
  );

  useEffect(() => {
    if (skip || loading || !subsidiaryID || !data?.subsidiary?.carriers?.nextToken) {
      return;
    }
    fetchMore({
      variables: { nextToken: data.subsidiary.carriers.nextToken },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        logger.log('useQueryWithSubscriptionSubsidiaryCarrierList: fetchMore subsidiary carriers', {
          subsidiaryID,
        });
        const result: SubsidiaryCarrierListQueryData = {
          ...previousResult,
          subsidiary: {
            ...previousResult.subsidiary,
            id: previousResult.subsidiary?.id || subsidiaryID,
            carriers: {
              ...previousResult.subsidiary?.carriers,
              items: [
                ...(previousResult.subsidiary?.carriers?.items || []),
                ...(fetchMoreResult.subsidiary?.carriers?.items || []),
              ],
              nextToken: fetchMoreResult.subsidiary?.carriers?.nextToken || null,
            },
          },
        };
        return result;
      },
    }).catch(() => {
      notification.error({
        message: i18n.t('general.notifications.fetchDataErrorTitle'),
        description: i18n.t('general.notifications.fetchDataErrorDescription'),
      });
    });
  }, [fetchMore, skip, loading, subsidiaryID, data?.subsidiary?.carriers?.nextToken]);

  useEffect(() => {
    if (subsidiaryID) {
      logger.log('useQueryWithSubscriptionSubsidiaryCarrierList: subscribing', {
        subsidiaryID,
        date: new Date().toISOString(),
        subscriptionsLength: subscriptionsRef.current?.length,
      });
      subscriptionsRef.current = [
        subscribeToMoreForCarrier(subsidiaryID),
        subscribeToMoreForSensorType(
          subsidiaryID,
          SENSOR_STATUS_QUERY.emergency_status,
          SENSOR_STATUS_VARIABLE.emergencyStatus,
        ),
        subscribeToMoreForSensorType(subsidiaryID, SENSOR_NAME_QUERY.gps, SENSOR_NAME_VARIABLE.gps),
        subscribeToMoreForSensorType(
          subsidiaryID,
          SENSOR_NAME_QUERY.brain_stop,
          SENSOR_NAME_VARIABLE.brainStop,
        ),
        subscribeToMoreForSensorType(
          subsidiaryID,
          SENSOR_NAME_QUERY.connected,
          SENSOR_NAME_VARIABLE.connected,
        ),
        subscribeToMoreForSensorType(
          subsidiaryID,
          SENSOR_NAME_QUERY.battery,
          SENSOR_NAME_VARIABLE.battery,
        ),
        subscribeToMoreForSensorType(
          subsidiaryID,
          SENSOR_STATUS_QUERY.battery_status,
          SENSOR_STATUS_VARIABLE.batteryStatus,
        ),
        subscribeToMoreForSensorType(
          subsidiaryID,
          SENSOR_NAME_QUERY.lte_signal_strength,
          SENSOR_NAME_VARIABLE.lteSignalStrength,
        ),
        subscribeToMoreForSensorType(
          subsidiaryID,
          SENSOR_STATUS_QUERY.lte_signal_strength_status,
          SENSOR_STATUS_VARIABLE.lteSignalStrengthStatus,
        ),
      ];
    }

    return () => {
      logger.log('useQueryWithSubscriptionSubsidiaryCarrierList: unsubscribing', {
        subsidiaryID,
        date: new Date().toISOString(),
        subscriptionsLength: subscriptionsRef.current?.length,
      });
      subscriptionsRef.current.forEach((fn) => fn());
      subscriptionsRef.current = [];
      subscribedFeaturesRef.current = new Set();
    };
  }, [subsidiaryID, client, subscribeToMoreForCarrier, subscribeToMoreForSensorType]);

  // needs to be separate from non-feature subscriptions in order to not cause
  // fast successive subscribing and unsubscribing (because features get loaded
  // at different time than other dependencies) which produces appsync error
  // https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/509

  useEffect(() => {
    if (subsidiaryID) {
      logger.log('useQueryWithSubscriptionSubsidiaryCarrierList: subscribing to features', {
        subsidiaryID,
        date: new Date().toISOString(),
        subscriptionsLength: subscriptionsRef.current?.length,
        subscribedFeaturesSize: subscribedFeaturesRef.current?.size,
      });
      if (
        companyFeatures.heartRateSensor &&
        !subscribedFeaturesRef.current.has('heartRateSensor')
      ) {
        subscribedFeaturesRef.current.add('heartRateSensor');
        subscriptionsRef.current = [
          ...subscriptionsRef.current,
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_NAME_QUERY.heart_rate,
            SENSOR_NAME_VARIABLE.heartRate,
          ),
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_STATUS_QUERY.heart_rate_status,
            SENSOR_STATUS_VARIABLE.heartRateStatus,
          ),
        ];
      }
      if (
        companyFeatures.physiologicalTemperatureSensor &&
        !subscribedFeaturesRef.current.has('physiologicalTemperatureSensor')
      ) {
        subscribedFeaturesRef.current.add('physiologicalTemperatureSensor');
        subscriptionsRef.current = [
          ...subscriptionsRef.current,
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_NAME_QUERY.body_multi_sensor_v1,
            SENSOR_NAME_VARIABLE.bodyMultiSensorV1,
          ),
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_STATUS_QUERY.body_multi_sensor_v1_status,
            SENSOR_STATUS_VARIABLE.bodyMultiSensorV1Status,
          ),
        ];
      }
      if (
        companyFeatures.bodyTemperatureSensor &&
        !subscribedFeaturesRef.current.has('bodyTemperatureSensor')
      ) {
        subscribedFeaturesRef.current.add('bodyTemperatureSensor');
        subscriptionsRef.current = [
          ...subscriptionsRef.current,
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_NAME_QUERY.body_temperature, // deprecated
            SENSOR_NAME_VARIABLE.bodyTemperature, // deprecated
          ),
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_STATUS_QUERY.body_temperature_status, // deprecated
            SENSOR_STATUS_VARIABLE.bodyTemperatureStatus, // deprecated
          ),
        ];
      }
      if (companyFeatures.gasSensor && !subscribedFeaturesRef.current.has('gasSensor')) {
        subscribedFeaturesRef.current.add('gasSensor');
        subscriptionsRef.current = [
          ...subscriptionsRef.current,
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_NAME_QUERY.gas,
            SENSOR_NAME_VARIABLE.gas,
          ),
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_STATUS_QUERY.gas_status,
            SENSOR_STATUS_VARIABLE.gasStatus,
          ),
        ];
      }
      if (
        companyFeatures.impactDetectionFront &&
        !subscribedFeaturesRef.current.has('impactDetectionFront')
      ) {
        subscribedFeaturesRef.current.add('impactDetectionFront');
        subscriptionsRef.current = [
          ...subscriptionsRef.current,
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_STATUS_QUERY.traak_front_status,
            SENSOR_STATUS_VARIABLE.traakFrontStatus,
          ),
        ];
      }
      if (
        companyFeatures.impactDetectionBack &&
        !subscribedFeaturesRef.current.has('impactDetectionBack')
      ) {
        subscribedFeaturesRef.current.add('impactDetectionBack');
        subscriptionsRef.current = [
          ...subscriptionsRef.current,
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_STATUS_QUERY.traak_back_status,
            SENSOR_STATUS_VARIABLE.traakBackStatus,
          ),
        ];
      }
    }
  }, [
    subsidiaryID,
    companyFeatures.heartRateSensor,
    companyFeatures.physiologicalTemperatureSensor,
    companyFeatures.bodyTemperatureSensor, // deprecated
    companyFeatures.gasSensor,
    companyFeatures.impactDetectionFront,
    companyFeatures.impactDetectionBack,
    subscribeToMoreForSensorType,
  ]);

  return useMemo(
    () => ({
      subsidiaryCarrierList: data?.subsidiary?.carriers?.items || [],
      isLoading: loading,
      error,
    }),
    [data?.subsidiary?.carriers?.items, loading, error],
  );
}
