import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { Button, Tooltip } from 'antd';
import { memo } from 'react';
import styled from 'styled-components';

import AgentLastUpdateCircle from '~/components/AgentLastUpdateCircle';
import { DETAILS_POPUP_TYPE } from '~/components/DetailsPopup/config/types';
import Link from '~/components/Link';
import TimeAgo from '~/components/TimeAgo';
import { CARRIER_MAP_ZOOM_LEVEL } from '~/config/defaults';
import routes from '~/config/routes';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { Agent } from '~/types/agent';
import { AGENT_STATUS } from '~/types/agent';
import type { Vehicle } from '~/types/vehicle';
import getAgentStatusColor from '~/utils/agent/getAgentStatusColor';
import { formatDate, formatTime } from '~/utils/dateTime';
import computeVehicleShorthandLabel from '~/utils/vehicle/computeVehicleShorthandLabel';

import AgentsNavigation from './components/AgentsNavigation';
import MuteButton from './components/MuteButton';
import StatusBadge from './components/StatusBadge';

const WrapperDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  padding-bottom: 0;

  ${theme.medias.extraSmall} {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

const ProfileNameDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const ProfileCircleDiv = styled.div`
  align-items: center;
  color: ${theme.colors.white};
  display: flex;
  font-weight: regular;
  justify-content: center;
  border-radius: 32px;
  margin-right: 0;
  font-size: 16px;
  height: 52px;
  width: 52px;
  border: 1px solid ${theme.colors.white};
  margin: 0;
`;

const TitleH1 = styled.h1`
  line-height: 1;
  margin: 0 0 0.25rem;
  font-size: 20px;
`;

const SubtitleH2 = styled.h2`
  line-height: 1;
  margin: 0;
  font-size: 16px;
  opacity: 0.6;
`;

const RightFlexDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const BottomDiv = styled.div`
  margin: 6px 16px 0 20px;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, auto);
  grid-gap: 8px;
`;

const BottomFlexDiv = styled.span`
  display: flex;
  align-items: flex-end;
  gap: 6px;

  ${theme.medias.extraSmall} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export interface Props {
  type: DETAILS_POPUP_TYPE;
  agent: Agent | undefined;
  vehicle: Vehicle | undefined;
}

const DetailsPopupHeader = memo(({ type, agent, vehicle }: Props) => {
  const { companyFeatures } = useCompanyFeatures();

  const isVehicle = type === DETAILS_POPUP_TYPE.vehicle;

  const id = isVehicle ? vehicle?.id : agent?.id;
  const status = (isVehicle ? vehicle?.status : agent?.status) ?? AGENT_STATUS.connectionLost;
  const isOffline = !!agent?.isOffline;
  const statusColor = status ? getAgentStatusColor({ status, isOffline }) : theme.colors.grey;
  const acronym = isVehicle
    ? computeVehicleShorthandLabel(vehicle?.id ?? '')
    : agent?.attributes?.acronym;
  const title =
    (isVehicle ? vehicle?.id || agent?.attributes?.plate_number : agent?.completeName) ||
    i18n.t('general.defaults.carrierNameDefault');
  const subtitle = isVehicle
    ? i18n.t('agentsPage.vehicle')
    : `${i18n.t('agentsPage.agent')}${agent?.team ? ` (${agent.team})` : ''}`;

  const lastUpdateDate = agent?.lastUpdate ? formatDate(agent.lastUpdate, 'readable') : null;
  const lastUpdateTime = agent?.lastUpdate ? formatTime(agent.lastUpdate, 'standard') : null;

  return (
    <>
      <WrapperDiv>
        <ProfileNameDiv>
          <ProfileCircleDiv
            style={{ backgroundColor: statusColor, borderColor: statusColor }}
            data-id="details-popup-bullet-label"
          >
            {acronym}
          </ProfileCircleDiv>
          <div>
            <TitleH1 data-id="details-popup-title">{title}</TitleH1>
            {subtitle && <SubtitleH2 data-id="details-popup-subtitle">{subtitle}</SubtitleH2>}
          </div>
        </ProfileNameDiv>
        <RightFlexDiv>
          {status === AGENT_STATUS.alert && <MuteButton />}
          <StatusBadge
            status={status ?? AGENT_STATUS.connectionLost}
            isOffline={isOffline}
            vehicle={vehicle}
            data-id="details-popup-alert-btn"
          />
          <Tooltip title={i18n.t('general.forms.close')} placement="top">
            <div>
              <Link
                data-id="DetailsPopupHeader-close"
                to={routes.map({ id, type, zoom: `${CARRIER_MAP_ZOOM_LEVEL}` })}
              >
                <Button type="default" icon={<CloseOutlined />} size="large" />
              </Link>
            </div>
          </Tooltip>
        </RightFlexDiv>
      </WrapperDiv>
      {(!isOffline || agent?.status === AGENT_STATUS.alert) && (
        <BottomDiv>
          <BottomFlexDiv>
            {companyFeatures.agentLastUpdate && agent?.lastUpdate && (
              <Tooltip title={`${lastUpdateDate} ${lastUpdateTime}`} placement="topLeft">
                <BottomFlexDiv style={{ cursor: 'help' }}>
                  <AgentLastUpdateCircle agent={agent} />
                  <div>
                    {i18n.t('general.defaults.lastUpdate')}: <TimeAgo date={agent.lastUpdate} />
                  </div>
                </BottomFlexDiv>
              </Tooltip>
            )}
          </BottomFlexDiv>
          <AgentsNavigation key={agent?.id} agent={agent} />
        </BottomDiv>
      )}
    </>
  );
});

DetailsPopupHeader.displayName = 'DetailsPopupHeader';

export default DetailsPopupHeader;
