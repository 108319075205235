import { memo } from 'react';
import styled from 'styled-components';

import RealtimeLocationDisabledBanner from '~/components/RealtimeLocationDisabledBanner';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import theme from '~/theme';

import AgentStatusFilter from './components/AgentStatusFilter';
import TeamsFilter from './components/TeamsFilter';

const GUTTER = '8px';

const ContainerDiv = styled.div`
  position: absolute;
  top: calc(${theme.dimensions.navbarHeight}px + ${GUTTER});
  left: ${GUTTER};
  padding-right: calc(40px + ${GUTTER});
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: ${GUTTER};
  align-items: flex-start;
  user-select: none;

  ${theme.medias.lteSmall} {
    grid-template-columns: minmax(0, 1fr);
    padding-right: calc(40px + ${GUTTER});
  }
`;

const DashboardOverlayTopLeft = memo(() => {
  const { companyFeatures } = useCompanyFeatures();

  return (
    <ContainerDiv>
      <AgentStatusFilter />
      {!companyFeatures.realtimeLocation ? <RealtimeLocationDisabledBanner /> : <TeamsFilter />}
    </ContainerDiv>
  );
});

DashboardOverlayTopLeft.displayName = 'DashboardOverlayTopLeft';

export default DashboardOverlayTopLeft;
