import { useReactiveVar } from '@apollo/client';
import { memo, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { newAlertVar } from '~/apollo/reactiveVariables/newAlertVar';
import DashboardOverlayTopLeft from '~/components/DashboardOverlayTopLeft';
import { DETAILS_POPUP_TYPE } from '~/components/DetailsPopup/config/types';
import MainMap from '~/components/maps/MainMap';
import {
  DEFAULT_GPS_POSITION,
  DEFAULT_MAP_ZOOM_LEVEL,
  CARRIER_MAP_ZOOM_LEVEL,
} from '~/config/defaults';
import routes from '~/config/routes';
import useAgentsContext from '~/context/useAgentsContext';
import useInitialLoading from '~/hooks/useInitialLoading';
import usePreventPageScroll from '~/hooks/usePreventPageScroll';
import useQueryParams from '~/hooks/useQueryParams';
import useSubsidiary from '~/hooks/useSubsidiary';
import type { GpsSensorType } from '~/types/sensor';

const MapPage = memo(() => {
  const query = useQueryParams();
  const navigate = useNavigate();

  const { carrierId } = useReactiveVar(newAlertVar);
  const { isInitialLoading } = useInitialLoading();
  const { getAgent, getVehicle } = useAgentsContext();
  const { currentSubsidiary } = useSubsidiary();

  usePreventPageScroll();

  const maybeVehicleOrAgentPosition = useMemo(
    () =>
      query.type === DETAILS_POPUP_TYPE.vehicle
        ? getVehicle(query.id ?? '')?.location
        : getAgent(query.id ?? '')?.sensors?.gps,
    [getAgent, getVehicle, query.id, query.type],
  );

  const centerPosition: GpsSensorType = useMemo(
    () => maybeVehicleOrAgentPosition || currentSubsidiary?.location || DEFAULT_GPS_POSITION,
    [currentSubsidiary?.location, maybeVehicleOrAgentPosition],
  );

  const zoom = useMemo(
    () =>
      centerPosition !== DEFAULT_GPS_POSITION
        ? Number(query.zoom) || DEFAULT_MAP_ZOOM_LEVEL
        : DEFAULT_MAP_ZOOM_LEVEL,
    [centerPosition, query?.zoom],
  );

  useEffect(() => {
    if (carrierId) {
      navigate(routes.map({ id: carrierId, zoom: `${CARRIER_MAP_ZOOM_LEVEL}` }));
    }
  }, [navigate, carrierId]);

  return (
    <>
      <MainMap
        key={`${currentSubsidiary?.id}${isInitialLoading}${JSON.stringify(query)}`}
        isLoading={isInitialLoading}
        isBlurred={false}
        initialZoom={zoom}
        initialLat={centerPosition.lat}
        initialLng={centerPosition.lng}
        followingZoom={undefined}
        followingLat={undefined}
        followingLng={undefined}
      />
      <DashboardOverlayTopLeft />
    </>
  );
});

MapPage.displayName = 'MapPage';

export default MapPage;
