import { Button, Tooltip } from 'antd';
import { memo } from 'react';
import styled from 'styled-components';

import IconAlertSmallWhite from '~/components/icons/IconAlertSmallWhite';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { AGENT_STATUS } from '~/types/agent';
import type { Vehicle } from '~/types/vehicle';
import getAgentStatusColor from '~/utils/agent/getAgentStatusColor';
import sortAgentsByStatusAndName from '~/utils/agent/sortAgentsByStatusAndName';

const StyledButton = styled(Button)`
  padding-left: 24px;
  padding-right: 31px;
  border: none;
  border-radius: 4px;
  display: flex;
  gap: 0.5rem;
  cursor: default;
`;

const H4 = styled.h4`
  margin: 0;
  font-size: 18px;
  color: ${theme.colors.white};
`;

const TooltipH5 = styled.h5`
  margin: 0 0 8px;
  font-size: 14px;
`;

const TooltipUl = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 12px;
`;

interface Props {
  status: AGENT_STATUS;
  isOffline: boolean;
  vehicle: Vehicle | undefined;
  'data-id'?: string;
}

const StatusBadge = memo(({ status, isOffline, vehicle, 'data-id': dataId }: Props) => {
  const hasAlarm = status === AGENT_STATUS.alert;

  const backgroundColor = getAgentStatusColor({ status, isOffline });

  const hasTooltip = vehicle && vehicle?.agents?.length > 0;

  const content = (
    <StyledButton
      type="default"
      size="large"
      data-id={dataId}
      style={{ backgroundColor, cursor: hasTooltip ? 'help' : 'default' }}
    >
      {hasAlarm && (
        <div style={{ transform: 'translateY(1px)' }}>
          <IconAlertSmallWhite />
        </div>
      )}
      <H4>
        {i18n.t(
          `general.generalAlarms.${isOffline && status !== AGENT_STATUS.alert ? 'offline' : status}`,
        )}
      </H4>
    </StyledButton>
  );

  if (hasTooltip) {
    const sortedAgents = sortAgentsByStatusAndName(vehicle.agents);

    return (
      <Tooltip
        placement="top"
        title={
          <>
            <TooltipH5>{`${i18n.t('agentsPage.vehicle')} (${vehicle.plateNumber})`}</TooltipH5>
            <TooltipUl>
              {sortedAgents.map((agent) => (
                <li key={agent.id}>
                  <b>{agent.name}:</b> {i18n.t(`general.generalAlarms.${agent.status}`)}
                </li>
              ))}
            </TooltipUl>
          </>
        }
      >
        {content}
      </Tooltip>
    );
  }

  return content;
});

StatusBadge.displayName = 'StatusBadge';

export default StatusBadge;
