import type { ApolloError } from '@apollo/client';
import { Wrapper as GoogleMapsWrapper } from '@googlemaps/react-wrapper';
import { memo } from 'react';
import styled from 'styled-components';

import LoadingSpinner from '~/components/LoadingSpinner';
import variables from '~/config/variables';
import i18n from '~/locales/i18n';

import LocationHistoryMapComponent, { type Props } from './LocationHistoryMapComponent';

const CenterDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoResultsDiv = styled.div``;

const LocationHistoryMap = memo(
  ({
    agentId,
    activeHourHover,
    activeHourClick,
    locationPoints,
    isLoading,
    error,
  }: Props & { error: ApolloError | undefined }) => {
    if (isLoading) {
      return (
        <CenterDiv>
          <LoadingSpinner />
        </CenterDiv>
      );
    }

    if (error || locationPoints.length === 0) {
      return (
        <CenterDiv>
          <NoResultsDiv>{error?.message || `${i18n.t('locationHistory.noData')}.`}</NoResultsDiv>
        </CenterDiv>
      );
    }

    return (
      // TODO: https://github.com/googlemaps/js-api-loader/issues/825
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <GoogleMapsWrapper apiKey={variables.googleMapsApiKey}>
        <LocationHistoryMapComponent
          agentId={agentId}
          activeHourHover={activeHourHover}
          activeHourClick={activeHourClick}
          locationPoints={locationPoints}
          isLoading={isLoading}
        />
      </GoogleMapsWrapper>
    );
  },
);

LocationHistoryMap.displayName = 'LocationHistoryMap';

export default LocationHistoryMap;
