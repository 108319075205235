import { memo } from 'react';
import styled from 'styled-components';

import useAlarmsContext from '~/context/useAlarmsContext';
import theme from '~/theme';
import sortAgentsByStatusAndName from '~/utils/agent/sortAgentsByStatusAndName';

import AgentFullCard from './components/AgentFullCard';
import DetailsPopupFooter from './components/DetailsPopupFooter';
import DetailsPopupHeader from './components/DetailsPopupHeader';
import PaperCard from './components/PaperCard';
import SectionInfo from './components/SectionInfo';
import VehicleAgentTabs from './components/VehicleAgentTabs';
import { DETAILS_POPUP_TYPE } from './config/types';
import useDetailsPopupContext from './context/useDetailsPopupContext';

const GUTTER = '8px';

const FixedDiv = styled.div<{ $hasAlert?: boolean }>`
  position: fixed;
  left: 0;
  top: ${theme.dimensions.navbarHeight}px;
  width: ${theme.dimensions.detailsPopupWidth}px;
  max-width: calc(100% - ${GUTTER} - ${GUTTER});
  margin: ${GUTTER};
  height: calc(
    100% - ${theme.dimensions.navbarHeight}px - ${GUTTER} - ${GUTTER} -
      ${(props) => (props.$hasAlert ? '56px' : '0px')}
  );
  z-index: ${theme.layers.base};
  overflow: auto;
  background: ${theme.colors.white};
  user-select: none;
  border-radius: 10px;
  border: 1px solid ${theme.colors.midGrey};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const WrapperDiv = styled.div`
  padding: 12px 16px 0;
`;

const DetailsPopupContent = memo(() => {
  const { type, agent, vehicle } = useDetailsPopupContext();
  const { hasAlert } = useAlarmsContext();

  if (!agent && !vehicle) {
    return null;
  }

  const isTypeAgent = type === DETAILS_POPUP_TYPE.agent;
  const isTypeVehicle = type === DETAILS_POPUP_TYPE.vehicle;

  const allAgents = (isTypeAgent && agent ? [agent] : vehicle?.agents || []).filter((a) => !!a);
  const sortedAgents = sortAgentsByStatusAndName(allAgents);

  const activeAgent = isTypeAgent ? sortedAgents[0] : agent;

  const vehicleAgents =
    agent?.isOffline && activeAgent ? [activeAgent, ...sortedAgents] : sortedAgents;

  return (
    <FixedDiv
      $hasAlert={hasAlert}
      data-id={isTypeAgent ? 'agent-details-popup' : 'vehicle-details-popup'}
    >
      <div>
        <DetailsPopupHeader type={type} agent={agent} vehicle={vehicle} />
        <WrapperDiv>
          {isTypeVehicle && (
            <VehicleAgentTabs agents={vehicleAgents} activeAgentId={activeAgent?.id} />
          )}
          <PaperCard>
            <SectionInfo agent={agent} vehicle={vehicle} />
          </PaperCard>
          <AgentFullCard key={activeAgent?.id} agent={activeAgent} />
        </WrapperDiv>
      </div>
      <DetailsPopupFooter type={type} agent={agent} vehicle={vehicle} />
    </FixedDiv>
  );
});

DetailsPopupContent.displayName = 'DetailsPopupContent';

export default DetailsPopupContent;
